.border-left-primary {
  border-left: 7px solid #63c3d1;
}

.border-left-success {
  border-left: 7px solid #8fd19e;
}

.border-left-default {
  border-left: 7px solid #4f5b69;
}

.border-left-bege {
  border-left: 7px solid #c5c84e;
}

.border-left-danger {
  border-left: 7px solid #ec5b5b;
}

.border-top-primary {
  border-top: 7px solid #63c3d1;
}

.border-top-success {
  border-top: 7px solid #8fd19e;
}

.border-top-default {
  border-top: 7px solid #4f5b69;
}

.border-top-bege {
  border-top: 7px solid #c5c84e;
}

.border-top-danger {
  border-top: 7px solid #ec5b5b;
}

.border-right-primary {
  border-right: 7px solid #63c3d1;
}

.border-right-success {
  border-right: 7px solid #8fd19e;
}

.border-right-default {
  border-right: 7px solid #4f5b69;
}

.border-right-bege {
  border-right: 7px solid #c5c84e;
}

.border-right-danger {
  border-right: 7px solid #ec5b5b;
}

.border-bottom-primary {
  border-bottom: 7px solid #63c3d1;
}

.border-bottom-success {
  border-bottom: 7px solid #8fd19e;
}

.border-v-success {
  border-bottom: 7px solid #8fd19e;
}

.border-bottom-default {
  border-bottom: 7px solid #4f5b69;
}

.border-bottom-bege {
  border-bottom: 7px solid #c5c84e;
}

.border-bottom-danger {
  border-bottom: 7px solid #ec5b5b;
}

.div-circle {
  border-radius: 50%;
  width: 72px;
  height: 72px;
  padding: 5px;
  background: #fff;
  border: 7px solid #666;
  color: #666;
  text-align: center;
  font: 32px Arial, sans-serif;
}