@media (max-width: 767px) {
    .barra-pesquisa {
      margin-top: 50px;
    }
  }
  
  @media (max-width: 576px) {
    .barra-pesquisa {
      margin-top: 50px;
    }
  }
  
  @media (max-width: 1200px) {
    .barra-pesquisaa {
      margin-top: 50px;
    }
  }
  
  @media (max-width: 992px) {
    .barra-pesquisa {
      margin-top: 50px;
    }
  }
  