@media (max-width: 767px) {
  .label-departamentos {
    width: 100%;
  }
  .container-menus {
    margin-top: 100px;
  }
}

@media (max-width: 576px) {
  .label-departamentos {
    width: 100%;
  }
  .container-menus {
    margin-top: 100px;
  }
}

@media (max-width: 1200px) {
  .label-departamentos {
    width: 100%;
  }
  .container-menus {
    margin-top: 100px;
  }
}

@media (max-width: 992px) {
  .label-departamentos {
    width: 100%;
  }
  .container-menus {
    margin-top: 100px;
  }
}

.slider-departamentos {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  overflow: auto;
  scroll-behavior: smooth;
  max-height: 100%;
  background-color: #000;
  color: #fff;
  transition: all 1s;
  z-index: 1002;
  transform: translateY(160px);
}

.slider-departamentos.close-departamentos {
  top: 0;
  height: 0;
}

ul {
  margin-left: 0%;
  margin-right: 0%;
  margin-top: 0%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
}

li {
  float: left;
}

li a,
.dropbtn {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.cool-link {
  display: inline-block;
  color: #000;
  text-decoration: none;
}

.cool-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: var(--cor-primaria);
  transition: width 0.3s;
}

.cool-link:hover::after {
  width: 100%;
}

li.Soccer {
  display: inline-block;
}

.Soccer-content {
  display: none;
  width: 99vw;
  max-height: 60vh;
  z-index: 99999;
  overflow: auto;
  overflow-y: auto;
  position: fixed;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 4px;
  left: 0;
  padding: 10px 50px 30px;
}

.Soccer-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  margin-right: 15px;
  width: 220px;
  font-size: 14px;
}

.Soccer-content a:hover {
  color: "white";
  background-color: #e2e2e2;
  opacity: 0.8;
}

.Soccer:hover .Soccer-content {
  display: block;
}
