.listgroupitem {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  height: 50px;
  background-color: #fff;
  line-height: 8px;
}

.listgroup {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.listgroupitem:last-child {
  border: none;
}

.listgroupitem:first-child {
  border: none;
}

.hover-cell-list:hover {
  transform: translate(-5px, 0);
  opacity: 0.65;
  box-shadow: 5px 0px;
}

.table-wrapper-scroll-y {
  display: block;
}

.my-custom-scrollbar {
  position: relative;
  height: 300px;
  overflow: auto;
}

@media (min-width: 576px) {
  .my-custom-scrollbar {
    position: relative;
    height: 200px;
    overflow: auto;
  }
}

@media (min-width: 768px) {
  .my-custom-scrollbar {
    position: relative;
    height: 200px;
    overflow: auto;
  }
}

@media (min-width: 992px) {
  .my-custom-scrollbar {
    position: relative;
    height: 200px;
    overflow: auto;
  }
}

@media (min-width: 1200px) {
  .my-custom-scrollbar {
    position: relative;
    height: 300px;
    overflow: auto;
  }
}