.login-mobioh {
  background-image: url("../../assets/images/bg-login.png");
}

.btn-bege {
  color: #fff;
  background-color: #c5c84e;
  border-color: #c5c84e;
}

.bg-bege {
  background-color: #c5c84e !important;
}