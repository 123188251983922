.view-absolute-item-combo {
  position: absolute;
  width: 90%;
  padding-left: 10px;
  padding-right: 10px;
  bottom: 0;
  margin: 10px;
  border-radius: 5px;
}

.view-absolute-label-combo {
  position: absolute;
  left: 72px;
  top: 20vh;
}

.view-opaca-item-combo {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1000;
}
