.input-search-lojainstantanea {
  background-color: transparent;
  border: none;
}

.input-search-lojainstantanea-mobile {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  transform: translateX(1500px);
  transition: 1s;
}

.input-search-lojainstantanea-mobile-active {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  transition: 1s;
  width: '100%';
  display: block;
}

.container-input-search-lojainstantanea {
  width: 610px;
}

.imagemlogoinput { 
  width: 170px;
}

@media (max-width: 400px) {
  .container-input-search-lojainstantanea {
    width: 350px;
  }

  .imagemlogoinput {
    width: 150px;
  }
}

@media (max-width: 767px) {
  .container-input-search-lojainstantanea {
    width: 350px;
  }
  .imagemlogoinput {
    width: 150px;
  }
}

@media (max-width: 576px) {
  .container-input-search-lojainstantanea {
    width: 350px;
  }
  .imagemlogoinput {
    width: 150px;
  }
}

@media (max-width: 1250px) {
  .container-input-search-lojainstantanea {
    width: 350px;
  }
  .imagemlogoinput {
    width: 150px;
  }
}

@media (max-width: 992px) {
  .container-input-search-lojainstantanea {
    width: 350px;
  }
  .imagemlogoinput {
    width: 100px;
  }
  .container-nome-usuario {
    display: none;
    opacity: 0;
    width: 0px;
  }
}

.container-input-search-lojainstantanea-mobile {
  position: "absolute";
  right: 10;
  top: 150;
}

.input-search-lojainstantanea::placeholder {
  color: var(--cor-primaria);
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.input-group {
  border-bottom: 1px solid #e5e5e5;
}
