@font-face {
  font-family: "MetropolisBold";
  src: url(metropolis-bold.otf);
}

@font-face {
  font-family: "MetropolisLight";
  src: url(metropolis-light.otf);
}

@font-face {
  font-family: "MetropolisSemiBold";
  src: url(metropolis-semi-bold.otf);
}

@font-face {
  font-family: "MetropolisRegular";
  src: url(metropolis-regular.otf);
}
