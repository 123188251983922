table tr td {
  padding: 0px !important;
}

table tr {
  font: Raleway;
  font-size: 12px;
}

.table > tbody > tr > td {
  vertical-align: middle;
  font: Raleway;
  font-size: 12px;
}

.tablenone {
  border: none;
}

.search-hidden {
  width: 0;
  display: none;
  transition: 0.5s;
}

.search-show {
  display: block;
  width: 170px;
}

.border-table {
  border-radius: 2px;
}

.border-none {
  border: none;
}

.color-selected-default {
  background-color: #dfe19e !important;
}

.font-small {
  font-size: 14px;
}

.table-responsive>.table-bordered {
  border: 1px solid #dee2e6 !important;
}

