@media (min-width: 576px) {
  .btnVoltarDadosPessoais {
    width: "500px";
    background-color: "#F9F9F9";
    color: "#878787";
    padding: 15;
    margin: 10;
    position: "absolute";
    right: 10;
    top: 10;
  }
}

@media (min-width: 768px) {
  .btnVoltarDadosPessoais {
    width: "500px";
    background-color: "#F9F9F9";
    color: "#878787";
    padding: 15;
    margin: 10;
    position: "absolute";
    right: 10;
    top: 10;
  }
}

@media (min-width: 992px) {
  .btnVoltarDadosPessoais {
    width: "500px";
    background-color: "#F9F9F9";
    color: "#878787";
    padding: 15;
    margin: 10;
    position: "absolute";
    right: 10;
    top: 10;
  }
}

@media (min-width: 1200px) {
  .btnVoltarDadosPessoais {
    width: "500px";
  }
}
