.headerProducts {
    font-size: 20px;
}
.iconProduct {
   color: #b2b2b2;
   font-size: 22px !important;
   margin: 4px 3px;
   position: absolute;
}
.imgProduct {
    width: 115px;
    margin: 5px 7px 0px 0px;
}
.textSubTitulo {
    color: #9e9e9e;
    font-size: 15px;
}
.precoTitulo {
    font-size: 32px;
}

.texto-dados-adicionais {
    color: #9e9e9e;
    font-size: 15px;
    font-weight: bold;
}

.texto-label {
    margin: 3px 10px 0px 35px;
}

.titulo-sessao {
    color: #9e9e9e;
    margin: 10px;
    font-size: 17px !important;
    font-weight: bold;
}

.titulo-precificacao {
    border: 1px solid #dee2e6;
    border-left-width: 9px;
    border-left-color: #4f5b69;
    padding: 13px;
    margin: 0 0 10px;
}

.custom-btn {
    width: 240px !important;
}