.btn-search {
    transform: translateX(180px);
    transition: 1s;
}

.btn-search-active {
    transform: translateX(-150px);
    transition: 1s;
}

.btn-deptos {
    transform: translateX(0);
    transition: 1s;
}

.btn-deptos-active {
    transform: translateX(-500px);
    transition: 1s;
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1010;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }

  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
    z-index: 1050;
  }

  .sidenav a:hover {
    color: #f1f1f1;
  }

  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }
    .sidenav a {
      font-size: 18px;
    }
  }

  .imagelogo {
    width: 200px;
  }

  .imagelogo-mobile {
      max-height: 50px;
  }

  @media (max-width: 767px) {
    .container-navbar {
      margin-top: 70px;
    }
    .imagelogo {
      width: 100px;
    }
  }

  @media (max-width: 576px) {
    .container-navbar {
      margin-top: 70px;
    }
    .imagelogo {
      width: 100px;
    }
  }

  @media (max-width: 1200px) {
    .container-navbar {
      margin-top: 70px;
    }
    .imagelogo {
      width: 100px;
    }
  }

  @media (max-width: 992px) {
    .container-navbar {
      margin-top: 70px;
    }
    .imagelogo {
      width: 100px;
    }
  }
