.list-dropmenu:hover {
  background-color: var(--cor-primaria);
  color: #fff;
}

.list-dropmenu:hover i {
  color: #fff !important;
}

.custom-drop {
  left: 273px !important;
}

.border-custom {
  border-top-color: #fff !important;
}

