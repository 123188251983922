.info_table:hover {
  background-color: "#46160E" !important;
  color: "#E94E25" !important;
}

@media (min-width: 576px) {
  .descricao-item {
    width: "10%";
  }
}

@media (min-width: 768px) {
  .descricao-item {
    width: "100px" !important;
  }
}

@media (min-width: 992px) {
  .descricao-item {
    width: "100px" !important;
  }
}

@media (min-width: 1200px) {
  .descricao-item {
    width: "100px" !important;
  }
}
