.container {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;  
  display: flex;
  flex-direction: column;
}

.section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.scrollable-content {
  flex-grow: 1;
  overflow: auto;
  min-height: 0;
}