.view-absolute-item {
  position: absolute;
  width: 90%;
  padding-left: 10px;
  padding-right: 10px;
  bottom: 0;
  margin: 10px;
  border-radius: 5px;
}

.view-absolute-item-mobile {
  position: absolute;
  width: 90%;
  padding-left: 10px;
  padding-right: 10px;
  bottom: 0;
  margin: 5px;
  border-radius: 5px;
}

.view-absolute-label {
  position: absolute;
  left: 72px;
  top: 90px;
}

.view-absolute-label-mobile {
  position: absolute;
  font-size: 13px;
  left: 20px;
  top: 15px;
}

.view-absolute-label-indisponivel-mobile {
  position: absolute;
  font-size: 11px;
  left: 10px;
  bottom: 15px;
}

.view-opaca-item {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1000;
}

.view-opaca-item-mobile {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  transform: translateY(-16px);
  z-index: 1000;
}

.view-opaca-item-mobile-indisponivel {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1000;
}

.border-label-departamento {
  border-bottom: 4px var(--cor-primaria) solid;
  border-radius: 2px;
  color: var(--cor-primaria);
  max-width: 30px;
}

.border-label-departamento-bottom {
  transform: translateY(-10px);
  margin-left: 30px;
}

.descricao-produto {
  max-width: 50ch;
}

.label-limpar-filtro-selecionado:hover {
  background-color: #f2f2f2;
}

.arrow-ver-mais {
  position: "absolute";
  top: 0;
  left: "42%";
}

.color-icon-depto {
  fill: green;
}

@media (min-width: 768px) {
  .label-adicionar-carrinho {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .label-adicionar-carrinho {
    font-size: 10px;
  }
}

@media (min-width: 1200px) {
  .label-adicionar-carrinho {
    font-size: 14px;
  }
}
