div.tooltips {
  position: relative;
  display: inline;
  cursor: pointer;
}
div.tooltips span {
  position: absolute;
  color: black;
  text-align: center;
  display: none;
}
div.show.tooltips span {
  display: block;
  opacity: 0.9;
  width: 100%;
  z-index: 999;
}

.top-20 {
  top: -20px;
}

.bottom-50 {
  bottom: 50%;
  bottom: -5px;
}
