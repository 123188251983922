.carousel-control-next,
.carousel-control-prev {
}

.indicator-carousel {
  border-radius: 11px !important;
  height: 5px !important;
}

.indicator-carousel.active {
  width: 50px !important;
  background-color: #e94e25 !important;
  transition: 0.5s;
}

.anuncio-banner-label:hover {
  color: #e94e25;
  background-color: "white";
}

.view-opaca-anuncio {
  background: rgba(0, 0, 0, 5);
  border-radius: 10px;
}

.view-opaca-anuncio img {
  opacity: 0.5;
}