.nav-side-menu {
  overflow: auto;
  font-family: verdana;
  font-size: 12px;
  font-weight: 200;
  background-color: #2e353d;
  position: fixed;
  top: 0px;
  width: 17px;
  height: 100%;
  color: #e1ffff;
  overflow-x: hidden;

  width: 0;
  background: #2d2e32 0% 0% no-repeat padding-box;
  transition: 0.5s;
}

.nav-side-menu.active {
  width: 200px;
}

.main {
  transition: 0.5s;
  margin-left: 0;
}

.main.active {
  margin-left: 200px;
}

#navbartop {
  transition: 0.5s;
  margin-left: 0;
}

#navbartop.active {
  margin-left: 200px;
}

@media (max-width: 767px) {
  .btnmenutop {
    display: none;
  }

  #navbartop.active {
    margin-left: 0;
  }

  .main.active {
    margin-left: 0;
  }

  .nav-side-menu.active {
    width: 100%;
  }
}

.nav-side-menu .brand {
  background: transparent linear-gradient(90deg, #2b737e 0%, #2d2e32 100%) 0% 0%
    no-repeat padding-box;
  line-height: 50px;
  display: block;
  text-align: center;
  font-size: 14px;
}
.nav-side-menu .toggle-btn {
  display: none;
}
.nav-side-menu ul,
.nav-side-menu li {
  list-style: none;
  padding: 0px;
  margin: 0px;
  line-height: 35px;
  cursor: pointer;
}
.nav-side-menu ul :not(collapsed) .arrow:before,
.nav-side-menu li :not(collapsed) .arrow:before {
  font-family: FontAwesome;
  content: "\f078";
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  float: right;
}
.nav-side-menu ul .active,
.nav-side-menu li .active {
  border-left: 3px solid #c5c84e;
  color: #c5c84e;
  background: #2d2e32 0% 0% no-repeat padding-box;
}
.nav-side-menu ul .sub-menu li.active,
.nav-side-menu li .sub-menu li.active {
  border-left: 3px solid #63c3d1;
  color: white;
  background-color: #4f5b69;
}
.nav-side-menu ul .sub-menu li.active a,
.nav-side-menu li .sub-menu li.active a {
  color: white;
}

.nav-side-menu ul .sub-menu li,
.nav-side-menu li .sub-menu li {
  background: #2c5860 0% 0% no-repeat padding-box;
  border: none;
  line-height: 28px;
  border-bottom: 1px solid #23282e;
  margin-left: 0px;
  color:white;
}

.nav-side-menu ul .sub-menu .sub-menu li,
.nav-side-menu li .sub-menu .sub-menu li {
  padding-left: 15px;
}

.nav-side-menu ul .sub-menu .sub-menu li.active,
.nav-side-menu li .sub-menu .sub-menu li.active {
  border-left: 3px solid #f4aa50;
}

.nav-side-menu ul .sub-menu .sub-menu .sub-menu li.active,
.nav-side-menu li .sub-menu .sub-menu .sub-menu li.active {
  border-left: 3px solid #f4adad;
}

.nav-side-menu ul .sub-menu .sub-menu .sub-menu li,
.nav-side-menu li .sub-menu .sub-menu .sub-menu li {
  padding-left: 30px;
}

.nav-side-menu ul .sub-menu li:hover,
.nav-side-menu li .sub-menu li:hover {
  border-left: 3px solid #63c3d1;
  background-color: #4f5b69;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.nav-side-menu ul .sub-menu .sub-menu li:hover,
.nav-side-menu li .sub-menu .sub-menu li:hover {
  border-left: 3px solid #f4aa50;
  background-color: #4f5b69;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.nav-side-menu ul .sub-menu .sub-menu .sub-menu li:hover,
.nav-side-menu li .sub-menu .sub-menu .sub-menu li:hover {
  border-left: 3px solid #f4adad;
  background-color: #4f5b69;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}


.nav-side-menu li {
  padding-left: 0px;
  border-left: 3px solid #2e353d;
  border-bottom: 1px solid #23282e;
}
.nav-side-menu li a {
  text-decoration: none;
  color: #e1ffff;
}
.nav-side-menu li a i {
  padding-left: 10px;
  width: 20px;
  padding-right: 20px;
}
.nav-side-menu li:hover {
  border-left: 3px solid #c5c84e;
  background-color: #4f5b69;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
@media (max-width: 767px) {
  .nav-side-menu {
    position: relative;
    width: 100%;
  }
  .nav-side-menu .toggle-btn {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10 !important;
    padding: 3px;
    background-color: #ffffff;
    color: #000;
    width: 40px;
    text-align: center;
  }

  .brand {
    text-align: left !important;
    font-size: 22px;
    padding-left: 20px;
    line-height: 50px !important;
  }
}
@media (min-width: 767px) {
  .nav-side-menu .menu-list .menu-content {
    display: block;
  }
}
body {
  margin: 0px;
  padding: 0px;
}

.menu-mobioh {
  background: #ffffff 0% 0% no-repeat padding-box;
}

.row-mobioh {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.show-logo-mobioh {
  transform: translate(0, 0);
  transition: 1s;
}

.show-logo-mobioh.active {
  transform: translate(0, -59px);
  transition: 1s;
}

.show-title-mobioh {
  transform: translate(0, 0);
  transition: 1s;
}

.show-title-mobioh.active {
  transform: translate(-60px, 0);
  transition: 1s;
}

.custom-nav-top {
  align-items: center;
  text-align: center;
  transform: translate3d(-240px, 38px, 0px) !important;
  min-width: 275px;
  width: auto;
  
}
