.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #63c3d1;
    background-color: #fff;
    border: 1px solid #dee2e6;
  }
  
  .page-link:hover {
    z-index: 2;
    color: #63c3d1;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }
  
  .page-item.active .pagelink {
    z-index: 1;
    color: #fff;
    background-color: #4f5b69;
    border-color: #4f5b69;
  }