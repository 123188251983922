.breadcrumb {
  background-color: #f5f5f5;
}
.breadcrumb-default {
  color: black;
}

.breadcrumb-default:hover {
  color: black;
  text-decoration: none;
}
