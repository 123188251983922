.color-picker-label {
    background-color: #e9ecef !important;
    border-radius: .25rem 0 0 .25rem !important;
    cursor: pointer;
  }

.color-picker-color  {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: red;
    border : 1px solid #e9ecef;
  }

 .color-picker-swatch {
    padding: 5px !important;
    background: #fff !important;
    border-radius: '0 .25rem .25rem 0' !important;
    cursor: pointer;
    width : 30% !important;
  }

 .color-picker-popover {
    position: 'absolute';
    z-Index: '2';
  }

.color-picker-cover {
    position: 'fixed';
    top: '0px';
    right: '0px';
    bottom: '0px';
    left: '0px';
}