.indicator-carousel {
  border-radius: 11px !important;
  height: 5px !important;
  width: 25px !important;
  bottom: 0;
}

.indicator-carousel.active {
  width: 50px !important;
  background-color: var(--cor-primaria) !important;
  transition: 0.5s;
}

.indicator-carousel-bar {
  border-radius: 15px !important;
  height: 20px !important;
  width: 100px !important;
  background-color: var(--cor-primaria) !important;
}

.indicator-carousel-bar.active {
  width: 100px !important;
  transition: 0.5s;
}

.container-ofertas-vertical-micro {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s;
}

.carousel-vertical-micro {
  transform: translateY(-25px);
}

.icon-prev-carousel-vertical-micro {
  background-color: var(--cor-primaria) !important;
  color: "white" !important;
  padding: 5px;
  width: 10%;
  border-radius: 10px;
  color: white !important;
}

.icon-prev-carousel-vertical-micro.active {
  width: 86% !important;
  transition: 0.5s;
}

.icon-carousel-mobile.active {
  width: 75% !important;
  transition: 0.5s;
}

.icon-carousel-mobile {
  background-color: var(--cor-primaria) !important;
  color: "white" !important;
  padding: 5px;
  width: 15%;
  border-radius: 10px;
  color: white !important;
}

.icon-next-carousel-vertical-micro {
  background-color: var(--cor-primaria) !important;
  padding: 5px;
  width: 10%;
  border-radius: 10px;
  color: white !important;
}

.icon-next-carousel-vertical-micro.active {
  width: 85% !important;
  transition: 0.5s;
}

.icon-prev-carousel-produto-generico {
  transform: translate(-40px, -70px);
  width: 30px;
}

.icon-prev-carousel-produto-generico-mobile {
  transform: translate(0px, -70px);
  width: 30px;
}

.icon-nex-carousel-produto-generico {
  transform: translate(40px, -70px);
  width: 30px;
}

.icon-next-btn-carousel-produto-generico-mobile {
  transform: translate(0px, -70px);
  width: 30px;
}

.icon-next-btn-carousel-produto-generico {
  transform: translate(70px, -70px);
  width: 30px;
}

.icon-nex-carousel-produto-combo {
  transform: translateX(100px);
}

.zindexcarousel {
  z-index: 1000;
}

.icon-prev-carousel-oferta-especial {
  background-color: var(--cor-primaria) !important;
  padding: 7px;
  width: 8%;
  border-radius: 10px;
  color: white !important;
  z-index: 1000;
}

.icon-prev-carousel-oferta-especial-mobile {
  background-color: var(--cor-primaria) !important;
  padding: 7px;
  width: 40px;
  border-radius: 10px;
  color: white !important;
  z-index: 1000;
}

.indicator-carousel-banner-top {
  border-radius: 11px !important;
  height: 5px !important;
  width: 50px !important;
  bottom: 0;
  background-color: var(--cor-primaria) !important;
}

.indicator-carousel-banner-top.active {
  width: 50px !important;
  background-color: var(--cor-primaria) !important;
  transition: 0.5s;
}

.view-opaca-anuncio {
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  position: "absolute";
}

.indicator-carousel-banner-top-mobile {
  border-radius: 11px !important;
  height: 15px !important;
  width: 30px !important;
  background-color: var(--cor-primaria) !important;
  position: "absolute";
}

.indicator-carousel-banner-top-mobile.active {
  width: 50px !important;
  transition: 0.5s;
}

.container-banner-top {
  margin-top: 0;
}

@media (max-width: 767px) {
  .container-banner-top {
    margin-top: 8%;
  }
}

@media (max-width: 576px) {
  .container-banner-top {
    margin-top: 8%;
  }
}

@media (max-width: 1200px) {
  .container-banner-top {
    margin-top: 8%;
  }
}

@media (max-width: 992px) {
  .container-banner-top {
    margin-top: 8%;
  }
}
