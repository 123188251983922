.contato-whatsapp-generico {
  display: flex;
	flex-direction: column;
  padding: 15px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  cursor: pointer;

	width: 95px;
  height: 100px;
  margin: 10px 0;

  font-size: 12px;
  font-weight: bolder;

  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  transition: ease-in-out 0.2s;
}
.contato-whatsapp-generico span {
  line-height: 1;
}
